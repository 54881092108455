<template>
    <div class="app-content-container">
        <v-responsive
            class="d-flex fill-height white app-content"
            height="100%"
            width="100%"
            :elevation="2"
        >
            <slot></slot>

        </v-responsive>
    </div>
</template>

<script>


export default {
    // This component describes a container to render all body content SpyderX-like.
    // You can include this component to your new page and use the slot, to render your content.
    // Example:
    // <ContentContainer title="MyHelloWorldPage">
    //     <h1>Hello World</h1>
    // </ContentContainer>

    name: "ContentContainer",

    // The following properties have to be passed from the parent component:
    // title (String): Used to show a title above the content.
    props: ["title"],
    
    computed: {
        // Calculates the minimal height of the content. This way it will be stretched to the bottom footer,
        // if it does not contain enough content.
        minHeight() {
            const height = this.$vuetify.breakpoint.mdAndUp ? "100vh" : "50vh";

            return `calc(${height} - ${this.$vuetify.application.top}px - 80px)`;
        },
    },

    components: {

    }
};
</script>

<style lang="sass">
.app-content-container
    padding: 25px 10px

.app-content
    border-radius: 5px
    padding: 25px 25px 10px 25px
</style>