var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-content-container" },
    [
      _c(
        "v-responsive",
        {
          staticClass: "d-flex fill-height white app-content",
          attrs: { height: "100%", width: "100%", elevation: 2 },
        },
        [_vm._t("default")],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }